// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-doc-page-js": () => import("/opt/build/repo/src/templates/doc-page.js" /* webpackChunkName: "component---src-templates-doc-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-delete-js": () => import("/opt/build/repo/src/pages/account/delete.js" /* webpackChunkName: "component---src-pages-account-delete-js" */),
  "component---src-pages-account-expired-js": () => import("/opt/build/repo/src/pages/account/expired.js" /* webpackChunkName: "component---src-pages-account-expired-js" */),
  "component---src-pages-account-index-js": () => import("/opt/build/repo/src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-login-js": () => import("/opt/build/repo/src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-unsubscribe-js": () => import("/opt/build/repo/src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

